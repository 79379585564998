<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <ValidationObserver v-slot="{ passes }">
                        <div class="header">
                            <h2>GT Bank account Number Validation Form</h2>
                        </div>
                        <div class="body">
                            <form @submit.prevent="passes(validateBank)" id="basic-form">
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <ValidationProvider name="bank" rules="required" v-slot="{ errors }">
                                            <div class="form-group form-select single-multiselect select-option-custom" :class="{'errors': errors[0],'success':user.bank && !errors[0]}">
                                                <span class="float-label">
                                                <label for="bank">Select Bank*</label>
                                                    <multiselect
                                                        placeholder=""
                                                        v-model="user.bank"
                                                        :show-labels="false" 
                                                        label="name"
                                                        :options="bank_options">
                                                    </multiselect>
                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                    <i class="fa fa-check" v-if="user.bank && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-md-6 col-12">
                                        <ValidationProvider name="account_no" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !user.account_no,'success':user.account_no}">
                                                <span class="float-label">
                                                    <input type="text" v-model="user.account_no" class="form-control" id="account_no" placeholder="Bank Account No*">
                                                    <label for="account_no" class="control-label">Bank Account No*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !user.account_no"></i>
                                                    <i class="fa fa-check" v-if="user.account_no"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="d-flex flex-column flex-md-row justify-content-center">
                                    <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">Find</b-button>
                                </div>
                            </form>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
export default {
    name: "ValidateBankComponent",
    components: {
        ValidationObserver,
        ValidationProvider,
        Multiselect: () => import('vue-multiselect'),
        BreadCrumb : () => import('@/components/BreadCrumb.vue'),
    },
    data: () => ({
        bank_options:[
            { value: 'ACCESS BANK', name:'ACCESS BANK [Exact Length: 13]'},
            { value: 'AGRICULTURAL DEVELOPMENT BANK', name:'AGRICULTURAL DEVELOPMENT BANK [Exact Length: 13]'},
            { value: 'AIRTEL-TIGO MONEY', name:'AIRTEL-TIGO MONEY [Min Length: 10]'},
            { value: 'Bank of Africa - Ghana Ltd', name:'Bank of Africa - Ghana Ltd [Min Length: 10]'},
            { value: 'BARCLAYS BANK', name:'BARCLAYS BANK [Exact Length: 10]'},
            { value: 'Cal Bank Limited', name:'Cal Bank Limited [Min Length: 10]'},
            { value: 'Consolidated Bank Ghana', name:'Consolidated Bank Ghana [Min Length: 10]'},
            { value: 'ECOBANK', name:'ECOBANK [Exact Length: 13]'},
            { value: 'FIDELITY BANK', name:'FIDELITY BANK [Exact Length: 13]'},
            { value: 'FIRST ATLANTIC BANK', name:'FIRST ATLANTIC BANK [Min Length: 10]'},
            { value: 'First Bank of Nigeria', name:'First Bank of Nigeria [Min Length: 10]'},
        ],

        user: {
            bank: null,
            account_no:''
        },
    }),
    methods: {
        validateBank() {
            console.log('submit');
        },

    }
};
</script>